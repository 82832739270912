import { AppLanguage, getLanguageStrings } from "internationalisation";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";

import { GCPL1024CollGroupId } from "respondent_config_generator/src/projects/GCPL-1024";
import { setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";

export type { GCPL1024CollGroupId } from 'respondent_config_generator/src/projects/GCPL-1024'

const getPlatformForGroupId = (groupId: GCPL1024CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "GCPL-432-1024-IN-YOUTUBE":
            return "youtubeShorts";
        default:
            assertNever(groupId)
    }
}

export const configureGCPL1024Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<GCPL1024CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getLanguageStrings(AppLanguage.en)

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}