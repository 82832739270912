import { assertNever } from "./helper/assertNever";
import { PanelConfiguration } from "./panels";
import { AmplifiedTVExtraMetaKey, AmplifiedTVGroupId, PanelConfigurationAmplifiedTV } from "./panels/amplified";
import { CintExtraMetaKey, CintGroupId, AmplifiedGroupId, isAmplifiedGroupId, PanelConfigurationCint } from "./panels/cint";
import { CintRedirectGroupId, PanelConfigurationCintRedirect, isRequiredGroupIdInExtrameta } from "./panels/cint-redirect";
import { MturkGroupId, PanelConfigurationMturk, PanelMetaMturk } from "./panels/mturk";
import { RespondentConfigBuilder } from "./respondentConfigBuilder";
import { RespondentConfig } from "./types";

export type { AmplifiedTVGroupId } from './panels/amplified';
export type { GroupId, RespondentConfig } from './types';

const generateConfig = (
    loginId: string,
    groupId: string,
    configBuilder: RespondentConfigBuilder,
    panelConfiguration: PanelConfiguration
): RespondentConfig => {
    configBuilder.addExtraMeta('loginId', loginId)
    if (isAmplifiedGroupId(groupId)) configBuilder.addExtraMeta('groupID', groupId)
    if (isRequiredGroupIdInExtrameta(groupId)) configBuilder.addExtraMeta('groupID', groupId)

    panelConfiguration.addExtraMetaAndFlags(configBuilder)
    panelConfiguration.addGroupConfig(configBuilder)
    panelConfiguration.addCompletionTasks(configBuilder)

    return configBuilder.build()
}

/**
 * Generate a Respondent Config JSON for a respondent from Cints Panel Buying API.
 *
 * @param groupID                 GroupID for Respondent, limited to those from projects supported by CINT
 * @param respondentID            RespondentID for Respondent
 * @param internalAssignmentID    AssignmentID for Respondent, if left undefined the App will automatically generate one
 * @param env                     Envrionment prefix to save assigment data into in atrace-app-uplaods
 * @param loginId                 LoginID for respondent
 * @param meta.panelRespondentId  CINT Respondent ID
 * @param meta.sandbox            Should the CINT API sandbox urls be used? (Optional, undefined is interpreted as false)
 *
 * @returns Respondent configuration from CINT parameters.
 */
export const generateCintConfig = (
    groupID: CintGroupId | CintRedirectGroupId | AmplifiedGroupId,
    respondentID: string,
    internalAssignmentID: string | undefined,
    env: string,
    loginId: string,
    meta: { panelRespondentId: string, sandbox?: boolean }
): RespondentConfig => {
    const configBuilder = new RespondentConfigBuilder(
        groupID,
        respondentID,
        internalAssignmentID,
        env
    );

    const panelConfiguration: PanelConfiguration = getCintPanelConfiguration(groupID, meta)

    return generateConfig(
        loginId,
        groupID,
        configBuilder,
        panelConfiguration
    )
}

/**
 * Generate a Respondent Config JSON based on an mTurk Respondent.
 * Normally, you would use the mTurk AssignmentID as the internalAssignmentID
 * as it allows us to easily find data associated with a Task submission.
 * However, if required these IDs can be different if required for other reasons
 * like testing
 *
 * @param groupID              GroupID for Respondent
 * @param respondentID         RespondentID for Respondent
 * @param internalAssignmentID AssignmentID for Respondent, if left undefined the App will automatically generate one
 * @param env                  Envrionment folder to save assigment data into
 * @param loginId              LoginID for respondent
 * @param mTurkMeta                 Object containing metadata about a generic panel respondent
 * @returns Respondent configuration from CAI parameters.
 */
export const generateMturkConfig = (
    groupID: MturkGroupId,
    respondentID: string,
    internalAssignmentID: string | undefined,
    env: string,
    loginId: string,
    mTurkMeta: Pick<PanelMetaMturk, 'hitId' | 'assignmentId' | 'workerId' | 'isMacbook' | 'completedDemographicSurvey'>
): RespondentConfig => {
    const configBuilder = new RespondentConfigBuilder<CintExtraMetaKey>(
        groupID,
        respondentID,
        internalAssignmentID,
        env
    );

    const panelConfiguration = new PanelConfigurationMturk({
        groupId: groupID,
        ...mTurkMeta
    })

    return generateConfig(
        loginId,
        groupID,
        configBuilder,
        panelConfiguration
    )
};

/**
 * Generate a Respondent Config JSON for a respondent who is part of a panel using Managed Devices for TV viewing.
 *
 * @param groupID                 GroupID for Respondent, limited to those from projects supported by our Managed Devices
 * @param respondentID            RespondentID for Respondent
 * @param internalAssignmentID    AssignmentID for Respondent, if left undefined the App will automatically generate one
 * @param env                     Envrionment prefix to save assigment data into in atrace-app-uplaods
 * @param loginId                 LoginID for respondent
 * @param aiMeta.panelRespondentId  Panel Respondent ID
 * @param aiMeta.panelRespondentId  Panel Indentifier (Thus far this is only `cint`)
 * @param aiMeta.viewingNumber      If the respondent/device is performing multiple viewings, which one is this? Counting starts from 1 and not 0
 *
 * @returns Respondent configuration from AT TV parameters.
 */
export function generateAITVConfig(
    groupId: AmplifiedTVGroupId,
    respondentId: string,
    internalAssignmentId: string | undefined,
    env: string,
    loginId: string,
    aiMeta: {
        panelRespondentId: string,
        panelIdentifier: 'cint',
        viewingNumber: number,
        user?: string,
        email?: string
    }
) {
    const configBuilder = new RespondentConfigBuilder<AmplifiedTVExtraMetaKey>(
        groupId,
        respondentId,
        internalAssignmentId,
        env
    )

    const panelConfiguration = new PanelConfigurationAmplifiedTV({
        groupId,
        panelIdentifier: aiMeta.panelIdentifier,
        panelRespondentId: aiMeta.panelRespondentId,
        viewingNumber: aiMeta.viewingNumber,
        user: aiMeta.user,
        email: aiMeta.email,
    })

    return generateConfig(
        loginId,
        groupId,
        configBuilder,
        panelConfiguration
    )
}

function getCintPanelConfiguration(
    groupID: CintGroupId | CintRedirectGroupId | AmplifiedGroupId,
    meta: {
        panelRespondentId: string,
        sandbox?: boolean
    }
): PanelConfiguration {
    switch (groupID) {
        case "OMDG-4":
        case "OMDG-5":
        case "AREM-15-MC":
        case "AREM-16-HTL":
        case "AREM-17-NTL":
        case "AUST-18-NEWSFEED":
        case "AUST-19-WATCH":
        case "AUST-20-STORIES":
        case "AUST-21-NEWSFEED":
        case "AUST-22-STORIES":
        case "AUST-23":
        case "AUST-24": {
            return new PanelConfigurationCintRedirect({
                groupId: groupID,
                redirectOnCompletion: true,
                panelRespondentId: meta.panelRespondentId,
                sandbox: meta.sandbox ?? false
            })
        }

        case "C9AU-27-1021-BVOD":
        case "TWIT-30-1221-UK-TWITTER":
        case "TWIT-31-1221-UK-INSTAGRAM":
        case "TWIT-32-1221-UK-FACEBOOK":
        case "TWIT-34-1221-UK-YOUTUBE":
        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
        case "TWIT-38-1221-CAN-TWITTER":
        case "TWIT-39-1221-CAN-INSTAGRAM":
        case "TWIT-40-1221-CAN-FACEBOOK":
        case "TWIT-42-1221-CAN-YOUTUBE":
        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
        case "TWIT-46-1221-FRA-TWITTER":
        case "TWIT-47-1221-FRA-INSTAGRAM":
        case "TWIT-48-1221-FRA-FACEBOOK":
        case "TWIT-50-1221-FRA-YOUTUBE":
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
        case "TWIT-54-1221-MEX-TWITTER":
        case "TWIT-55-1221-MEX-INSTAGRAM":
        case "TWIT-56-1221-MEX-FACEBOOK":
        case "TWIT-58-1221-MEX-YOUTUBE":
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
        case "TWIT-33-1221-UK-TIKTOK":
        case "TWIT-41-1221-CAN-TIKTOK":
        case "TWIT-49-1221-FRA-TIKTOK":
        case "TWIT-57-1221-MEX-TIKTOK":
        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC":
        case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES":
        case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS":
        case "REDC-70-0122-IRL-FACEBOOK-INFEED":
        case "REDC-71-0122-IRL-FACEBOOK-STORIES":
        case "YAHO-72-0422-USA-YAHOO-ITW":
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
        case "YAHO-74-0422-USA-FINANCE-ITW":
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
        case "YAHO-76-0422-USA-LIFESTYLE-ITW":
        case "YAHO-83-0422-CAN-YAHOO-ITW":
        case "YAHO-84-0422-CAN-SPORTS-ITW":
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
        case "YAHO-86-0422-CAN-FINANCE-ITW":
        case "YAHO-87-0422-CAN-AOL-ITW":
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
        case "YAHO-94-0422-AUS-YAHOO-ITW":
        case "YAHO-95-0422-AUS-FINANCE-ITW":
        case "YAHO-96-0422-AUS-SPORTS-ITW":
        case "YAHO-102-0422-UK-YAHOO-ITW":
        case "YAHO-103-0422-UK-AOL-ITW":
        case "YAHO-104-0422-UK-FINANCE-ITW":
        case "YAHO-105-0422-UK-STYLE-ITW":
        case "YAHO-106-0422-UK-SPORTS-ITW":
        case "YAHO-77-0422-USA-YAHOO-EXPOSED":
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
        case "YAHO-79-0422-USA-FINANCE-EXPOSED":
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
        case "YAHO-81-0422-USA-MSN-EXPOSED":
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
        case "YAHO-92-0422-CAN-MSN-EXPOSED":
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
        case "YAHO-99-0422-AUS-MSN-EXPOSED":
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
        case "YAHO-107-0422-UK-YAHOO-EXPOSED":
        case "YAHO-108-0422-UK-AOL-EXPOSED":
        case "YAHO-109-0422-UK-MSN-EXPOSED":
        case "YAHO-110-0422-UK-FINANCE-EXPOSED":
        case "YAHO-111-0422-UK-SPORTS-EXPOSED":
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED":
        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES":
        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS":
        case "ODEU-118-0422-DEU-TWITTER-INFEED":
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV":
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV":
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV":
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV":
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV":
        case "ADCO-146-0622-UK-ADCOLONY":
        case "VIAB-149-0822-BE-NORTH-YOUTUBE":
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE":
        case "VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2":
        case "VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2":
        case "ACAY-151-0822-AUS-YOUTUBE":
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT":
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT":
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT":
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT":
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT":
        case 'OMNZ-217-NZ-FACEBOOK':
        case 'OMNZ-218-NZ-INSTAGRAM':
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL':
        case 'OMNZ-220-NZ-TWITTER-FEED':
        case 'OMNZ-221-NZ-TIKTOK-FEED':
        case 'OMNZ-222-NZ-STUFF-EXPOSED':
        case 'OMNZ-223-NZ-NZME-EXPOSED':
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT':
        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD":
        case 'OMME-234-SAU-FACEBOOK':
        case 'OMME-235-SAU-INSTAGRAM':
        case 'OMME-236-SAU-YOUTUBE-PREROLL':
        case 'OMME-237-SAU-TWITTER-FEED':
        case 'OMME-238-SAU-TIKTOK-FEED':
        case 'OMME-239-SAU-ALMARSD-EXPOSED':
        case 'OMME-240-SAU-ALARABIYA-EXPOSED':
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
        case 'OMME-242-SAU-MOTORY-EXPOSED':
        case 'OMME-243-SAU-SO3ODY-EXPOSED':
        case 'OMME-253-UAE-FACEBOOK':
        case 'OMME-254-UAE-INSTAGRAM':
        case 'OMME-255-UAE-YOUTUBE-PREROLL':
        case 'OMME-256-UAE-TWITTER-FEED':
        case 'OMME-257-UAE-TIKTOK-FEED':
        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED":
        case "TWIC-258-1222-US-MOBILE-ITW":
        case "TWIC-259-1222-UK-MOBILE-ITW":
        case "TWIC-260-1222-AU-MOBILE-ITW":
        case "TWIH-268-0123-US-DESKTOP-ITW":
        case "TWIH-269-0123-UK-DESKTOP-ITW":
        case "TWIH-270-0123-AU-DESKTOP-ITW":
        case "TWIH-271-0123-US-DESKTOP-INT":
        case "TWIH-272-0123-UK-DESKTOP-INT":
        case "TWIH-273-0123-AU-DESKTOP-INT":
        case "SPOT-264-0123-AU-MUSIC":
        case "SPOT-265-0123-AU-PODCAST":
        case "SPOT-266-0123-NZ-MUSIC":
        case "SPOT-267-0123-NZ-PODCAST":
        case "PEPS-274-0523-FACEBOOK-ESTABLISHED":
        case "PEPS-275-0523-FACEBOOK-NON":
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED":
        case "PEPS-277-0523-INSTAGRAM-NON":
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED":
        case "PEPS-279-0523-YOUTUBE-NON":
        case "PEPS-280-0523-TIKTOK-ESTABLISHED":
        case "PEPS-281-0523-TIKTOK-NON":
        case "GYGG-282-0623-NY-FACEBOOK":
        case "GYGG-283-0623-LA-FACEBOOK":
        case "GYGG-284-0623-NY-INSTAGRAM":
        case "GYGG-285-0623-LA-INSTAGRAM":
        case "GYGG-286-0623-NY-YOUTUBE":
        case "GYGG-287-0623-LA-YOUTUBE":
        case "SPIN-288-0823-SPOTIFY":
        case "SPIN-289-0823-FACEBOOK":
        case "SPIN-290-0823-INSTAGRAM":
        case "SPIN-291-0823-YOUTUBE":
        case "SPIN-292-0823-FB-SPOT":
        case "SPIN-293-0823-IG-SPOT":
        case "SPIN-294-0823-YT-SPOT":
        case "SNAR-295-0823-VALIDATION":
        case "PINT-326-1123-US-GROUP1":
        case "PINT-327-1123-US-GROUP2":
        case "PINT-328-1123-US-GROUP3":
        case "PINT-329-1123-US-GROUP4":
        case "PINT-330-1123-UK-GROUP1":
        case "PINT-331-1123-UK-GROUP2":
        case "PINT-332-1123-UK-GROUP3":
        case "PINT-333-1123-UK-GROUP4":
        case "PINT-334-1123-AU-GROUP1":
        case "PINT-335-1123-AU-GROUP2":
        case "PINT-336-1123-AU-GROUP3":
        case "PINT-337-1123-AU-GROUP4":
        case "PINT-338-1123-FR-GROUP1":
        case "PINT-339-1123-FR-GROUP2":
        case "PINT-340-1123-FR-GROUP3":
        case "PINT-341-1123-FR-GROUP4":
        case "PINT-342-1123-DE-GROUP1":
        case "PINT-343-1123-DE-GROUP2":
        case "PINT-344-1123-DE-GROUP3":
        case "PINT-345-1123-DE-GROUP4":
        case "PINT-346-1123-CA-GROUP1":
        case "PINT-347-1123-CA-GROUP2":
        case "PINT-348-1123-CA-GROUP3":
        case "PINT-349-1123-CA-GROUP4":
        case "SKYN-296-0723-BVOD-MOBILE":
        case "RCTV-297-0523-BVODMOBILE":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
        case "GYGG-350-0124-US-YOUTUBE-1":
        case "GYGG-351-0124-US-YOUTUBE-2":
        case "GYGG-364-0124-US-FACEBOOK":
        case "GYGG-365-0124-US-INSTAGRAM":
        case "GYGG-366-0124-US-TIKTOK":
        case "GYGG-367-0124-US-YOUTUBE-3":
        case "GYGG-368-0124-UK-YOUTUBE-4":
        case "GYGG-370-0124-US-YOUTUBE-5":
        case "GYGG-371-0124-UK-YOUTUBE-6":
        case "SPAU-369-0324-SPOTIFY":
        case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
        case "ALLW-376-0424-UK-LOTTO-TIKTOK":
        case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
        case "SNEM-379-0524-AU-SNAP-HIGH":
        case "SNEM-380-0524-AU-SNAP-MED":
        case "SNEM-381-0524-AU-SNAP-LOW":
        case "SNEM-382-0524-AU-YOUTUBE":
        case "SNEM-383-0524-AU-TIKTOK-HIGH":
        case "SNEM-384-0524-AU-TIKTOK-MED":
        case "SNEM-385-0524-AU-TIKTOK-LOW":
        case "SNEM-386-0524-AU-INSTA-HIGH":
        case "SNEM-387-0524-AU-INSTA-MED":
        case "SNEM-388-0524-AU-INSTA-LOW":
        case "SNEM-389-0524-FR-SNAP-HIGH":
        case "SNEM-390-0524-FR-SNAP-MED":
        case "SNEM-391-0524-FR-SNAP-LOW":
        case "SNEM-392-0524-FR-YOUTUBE":
        case "SNEM-393-0524-FR-TIKTOK-HIGH":
        case "SNEM-394-0524-FR-TIKTOK-MED":
        case "SNEM-395-0524-FR-TIKTOK-LOW":
        case "SNEM-396-0524-FR-INSTA-HIGH":
        case "SNEM-397-0524-FR-INSTA-MED":
        case "SNEM-398-0524-FR-INSTA-LOW":
        case "SNEM-399-0524-SA-SNAP-HIGH":
        case "SNEM-400-0524-SA-SNAP-MED":
        case "SNEM-401-0524-SA-SNAP-LOW":
        case "SNEM-402-0524-SA-YOUTUBE":
        case "SNEM-403-0524-SA-TIKTOK-HIGH":
        case "SNEM-404-0524-SA-TIKTOK-MED":
        case "SNEM-405-0524-SA-TIKTOK-LOW":
        case "SNEM-406-0524-SA-INSTA-HIGH":
        case "SNEM-407-0524-SA-INSTA-MED":
        case "SNEM-408-0524-SA-INSTA-LOW":
        case "SNEM-409-0524-UK-SNAP-HIGH":
        case "SNEM-410-0524-UK-SNAP-MED":
        case "SNEM-411-0524-UK-SNAP-LOW":
        case "SNEM-412-0524-UK-YOUTUBE":
        case "SNEM-413-0524-UK-TIKTOK-HIGH":
        case "SNEM-414-0524-UK-TIKTOK-MED":
        case "SNEM-415-0524-UK-TIKTOK-LOW":
        case "SNEM-416-0524-UK-INSTA-HIGH":
        case "SNEM-417-0524-UK-INSTA-MED":
        case "SNEM-418-0524-UK-INSTA-LOW":
        case "SNEM-419-0524-US-SNAP-HIGH":
        case "SNEM-420-0524-US-SNAP-MED":
        case "SNEM-421-0524-US-SNAP-LOW":
        case "SNEM-422-0524-US-YOUTUBE":
        case "SNEM-423-0524-US-TIKTOK-HIGH":
        case "SNEM-424-0524-US-TIKTOK-MED":
        case "SNEM-425-0524-US-TIKTOK-LOW":
        case "SNEM-426-0524-US-INSTA-HIGH":
        case "SNEM-427-0524-US-INSTA-MED":
        case "SNEM-428-0524-US-INSTA-LOW":
        case "GCPL-429-0624-FB-IG":
        case "GCPL-430-0624-IG-YT":
        case "GCPL-431-0624-FB-YT":
        case "GCPL-432-1024-IN-YOUTUBE":
        case "GYGG-433-1024-US-INSTAGRAM":
        case "GYGG-434-1024-US-YOUTUBE":
            {
                return new PanelConfigurationCintRedirect({
                    groupId: groupID,
                    panelRespondentId: meta.panelRespondentId,
                    redirectOnCompletion: false,
                    sandbox: meta.sandbox ?? false
                })
            }

        case "OMDG-1A":
        case "OMDG-1B":
        case "OMDG-1C":
        case "OMDG-2A":
        case "OMDG-2B":
        case "OMDG-2C":
        case "OMDG-3A":
        case "OMDG-3B":
        case "OMDG-3C":
        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
        case "YAHD-303-0823-USA-YAHOO-MOBILE":
        case "CRAA-152-0822-SCA-ADL-BREAKFAST":
        case "CRAA-153-0822-SCA-SYD-BREAKFAST":
        case "CRAA-154-0822-SCA-MEL-BREAKFAST":
        case "CRAA-155-0822-SCA-BNE-BREAKFAST":
        case "CRAA-156-0822-SCA-PER-BREAKFAST":
        case "CRAA-157-0822-SCA-ALL-MORNAFT":
        case "CRAA-158-0822-SCA-ALL-DRIVE":
        case "CRAA-159-0822-SCA-ALL-EVENINGS":
        case "CRAA-160-0822-SCA-PODCAST-THEBRIEFING":
        case "CRAA-161-0822-SCA-PODCAST-HAMISHANDY":
        case "CRAA-162-0822-ARN-ADL-BREAKFAST":
        case "CRAA-163-0822-ARN-SYD-BREAKFAST":
        case "CRAA-164-0822-ARN-MEL-BREAKFAST":
        case "CRAA-165-0822-ARN-BNE-BREAKFAST":
        case "CRAA-166-0822-ARN-PER-BREAKFAST":
        case "CRAA-167-0822-ARN-ALL-MORNAFT":
        case "CRAA-168-0822-ARN-ALL-DRIVE":
        case "CRAA-169-0822-ARN-ADL-EVENING":
        case "CRAA-170-0822-ARN-SYD-EVENING":
        case "CRAA-171-0822-ARN-MEL-EVENING":
        case "CRAA-172-0822-ARN-BNE-EVENING":
        case "CRAA-173-0822-ARN-PER-EVENING":
        case "CRAA-174-0822-ARN-PODCAST-IMPERFECTS":
        case "CRAA-175-0822-ARN-PODCAST-KYLEJACKIE":
        case "CRAA-176-0822-ARN-PODCAST-LIFEUNCUT":
        case "CRAA-177-0822-NINE-SYD-BREAKFAST":
        case "CRAA-178-0822-NINE-MEL-BREAKFAST":
        case "CRAA-179-0822-NINE-BNE-BREAKFAST":
        case "CRAA-180-0822-NINE-PER-BREAKFAST":
        case "CRAA-181-0822-NINE-SYD-MORNAFT":
        case "CRAA-182-0822-NINE-MEL-MORNAFT":
        case "CRAA-183-0822-NINE-BNE-MORNAFT":
        case "CRAA-184-0822-NINE-PER-MORNAFT":
        case "CRAA-185-0822-NINE-SYD-DRIVE":
        case "CRAA-186-0822-NINE-MEL-DRIVE":
        case "CRAA-187-0822-NINE-BNE-DRIVE":
        case "CRAA-188-0822-NINE-PER-DRIVE":
        case "CRAA-189-0822-NINE-SYD-EVENING":
        case "CRAA-190-0822-NINE-MEL-EVENING":
        case "CRAA-191-0822-NINE-BNE-EVENING":
        case "CRAA-192-0822-NINE-PER-EVENING":
        case "CRAA-193-0822-NINE-PODCAST-MONEYNEWS":
        case "CRAA-194-0822-NOVA-ADL-BREAKFAST":
        case "CRAA-195-0822-NOVA-SYD-BREAKFAST":
        case "CRAA-196-0822-NOVA-MEL-BREAKFAST":
        case "CRAA-197-0822-NOVA-BNE-BREAKFAST":
        case "CRAA-198-0822-NOVA-PER-BREAKFAST":
        case "CRAA-199-0822-NOVA-AMBP-MORNAFT":
        case "CRAA-200-0822-NOVA-SYD-MORNAFT":
        case "CRAA-201-0822-NOVA-ALL-DRIVE":
        case "CRAA-202-0822-NOVA-ALL-EVENING":
        case "CRAA-203-0822-NOVA-PODCAST-HENNESSYS":
        case "CRAA-204-0822-NOVA-PODCAST-KTJ":
        case "CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE": {
            return new PanelConfigurationCint({
                groupId: groupID,
                panelRespondentId: meta.panelRespondentId
            })
        }

        default: assertNever(groupID)
    }
}
