import { PanelConfiguration, PanelMetaBase } from ".";
import { assertNever } from "../helper/assertNever";
import { addAreMediaConfig } from "../projects/AREM-AreMedia";
import { addAustCollConfig } from "../projects/AUST-AustralianFB";
import { addCh9MobileCollConfig } from "../projects/C9AU-Channel9";
import { addOdeu0422CollConfig, ODEU0422CollGroupId } from "../projects/ODEU-0422";
import { addOmdGladeConfig } from "../projects/OMDG-OmdGlade";
import { addRedc0122CollConfig, REDC0122CollGroupId } from "../projects/REDC-0122";
import { addTEAD1222CollConfig, TEAD1222CollGroupId } from "../projects/TEAD-1222";
import { addTwit1221CollConfig, TWIT1221CollGroupId } from "../projects/TWIT-1221";
import { addYaho0422CollConfig, YAHO0422CollGroupId } from "../projects/YAHO-0422";
import { addSnap0522CollConfig, SNAP0522CollGroupId } from "../projects/SNAP-0522";
import { addAdco0622CollConfig, ADCO0622CollGroupId } from "../projects/ADCO-0622";
import { addAcay0822CollWebplatformConfig, ACAY0822CollGroupId } from "../projects/ACAY-0822";
import { addViab0822CollWebplatformConfig, VIAB0822CintGroupId, addViab0822CollBvodMobileConfig } from "../projects/VIAB-0822";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalLinkTask } from "../tasks";
import { addOmnz1022CollWebplatformConfig, OMNZ1022WebGroupId } from "../projects/OMNZ-1022";
import { addOMME1022CollWebplatformConfig, OMME1022WebGroupId } from "../projects/OMME-1022";
import { addNeau1022CollConfig, NEAU1022CollGroupId } from "../projects/NEAU-1022";
import { addTwic1222CollConfig, TWIC1222CollGroupId } from "../projects/TWIC-1222";
import { addTWIH0123CollConfig, TWIH0123CollGroupId } from "../projects/TWIH-0123";
import { addSPOT0123CollConfig, SPOT0123CollGroupId } from "../projects/SPOT-0123";
import { PEPS0523CollGroupId, addPEPS0523CollConfig } from "../projects/PEPS-0523";
import { GYGG0623CollGroupId, addGYGG0623CollConfig } from "../projects/GYGG-0623";
import { SPIN0823CollGroupId, addSPIN0823CollConfig } from "../projects/SPIN-0823";
import { SNAR0823CollGroupId, addSNAR0823CollConfig, SNAR0823GeneralGroupId } from "../projects/SNAR-0823";
import { PINT1123CollGroupId, PINT1123CollGroupIds, addPINT1123CollConfig } from "../projects/PINT-1123";
import { SKYN0823CollGroupId, addSKYN0823CollConfig } from "../projects/SKYN-0823";
import { RCTV0523CollGroupId, addRCTV0523CollConfig } from "../projects/RCTV-0523";
import { addGYGG0124CollConfig, GYGG0124CollGroupId, GYGG0124CollGroupIds } from "../projects/GYGG-0124";
import { SPAU0324CollGroupId, addSPAU0324CollConfig } from "../projects/SPAU-0324";
import { ALLW0424CollGroupId, addALLW0424CollConfig } from "../projects/ALLW-0424";
import { SNEM0524CollGroupId, addSNEM0524CollConfig } from "../projects/SNEM-0524";
import { addGCPL0624CollConfig, GCPL0624CollGroupId } from "../projects/GCPL-0624";
import { addGCPL1024CollConfig, GCPL1024CollGroupId } from "../projects/GCPL-1024";
import { addGYGG1024CollConfig, GYGG1024CollGroupId } from "../projects/GYGG-1024";

const cintRedirectGroupIds = [
    'OMDG-4',
    'OMDG-5',

    'AREM-15-MC',
    'AREM-16-HTL',
    'AREM-17-NTL',

    'AUST-18-NEWSFEED',
    'AUST-19-WATCH',
    'AUST-20-STORIES',
    'AUST-21-NEWSFEED',
    'AUST-22-STORIES',
    'AUST-23',
    'AUST-24',

    'C9AU-27-1021-BVOD',
] as const;

export type CintRedirectGroupId =
    | typeof cintRedirectGroupIds[number]
    | TWIT1221CollGroupId
    | REDC0122CollGroupId
    | YAHO0422CollGroupId
    | ODEU0422CollGroupId
    | SNAP0522CollGroupId
    | ADCO0622CollGroupId
    | ACAY0822CollGroupId
    | VIAB0822CintGroupId
    | OMNZ1022WebGroupId
    | OMME1022WebGroupId
    | NEAU1022CollGroupId
    | TEAD1222CollGroupId
    | TWIC1222CollGroupId
    | TWIH0123CollGroupId
    | SPOT0123CollGroupId
    | PEPS0523CollGroupId
    | GYGG0623CollGroupId
    | SPIN0823CollGroupId
    | SNAR0823CollGroupId
    | PINT1123CollGroupId
    | SKYN0823CollGroupId
    | RCTV0523CollGroupId
    | GYGG0124CollGroupId
    | SPAU0324CollGroupId
    | ALLW0424CollGroupId
    | SNEM0524CollGroupId
    | GCPL0624CollGroupId
    | GCPL1024CollGroupId
    | GYGG1024CollGroupId

export const isCintRedirectGroupId = (groupId: string): groupId is CintRedirectGroupId => {
    return (cintRedirectGroupIds as ReadonlyArray<string>).includes(groupId)
}

const requiredGroupIdInExtrametaGroups = [
    ...SNAR0823GeneralGroupId,
    ...PINT1123CollGroupIds,
    ...GYGG0124CollGroupIds
] as const;

export const isRequiredGroupIdInExtrameta = (groupId: string): groupId is CintRedirectGroupId => {
    return (requiredGroupIdInExtrametaGroups as ReadonlyArray<string>).includes(groupId);
}

const cintExtraMetaKeys = [
    'panelIdentifier',
    'panelRespondentID'
] as const

export type CintRedirectExtraMetaKey = typeof cintExtraMetaKeys[number]

export type PanelMetaCintRedirect = PanelMetaBase<CintRedirectGroupId> & {
    panelRespondentId: string,
    /**
     * Should we add an externalLink redirecting the respondent
     * back to cint after completing their tasks
     */
    redirectOnCompletion: boolean,
    sandbox: boolean,
};

function getCintExternalLinkUrl(sandbox: boolean, panelRespondentId: string): string {
    if (sandbox) {
        // Sandbox
        return `https://s.cintworks.net/survey/return/${panelRespondentId}`
    }

    // Production
    return `https://s.cint.com/survey/return/${panelRespondentId}`
}

export class PanelConfigurationCintRedirect implements PanelConfiguration {
    constructor(
        private meta: PanelMetaCintRedirect
    ) { }

    keys = cintExtraMetaKeys

    addExtraMetaAndFlags(configBuilder: RespondentConfigBuilder<CintRedirectExtraMetaKey>) {
        configBuilder
            .addExtraMeta('panelRespondentID', this.meta.panelRespondentId)
            .addExtraMeta('panelIdentifier', 'cint')
    }

    addCompletionTasks(configBuilder: RespondentConfigBuilder<CintRedirectExtraMetaKey>) {
        if (this.meta.redirectOnCompletion) {
            const redirectTask = generateExternalLinkTask({
                title: "Panel Redirect",
                description: "",
                taskID: `${this.meta.groupId}-CINT-REDITECT`,
                url: getCintExternalLinkUrl(this.meta.sandbox, this.meta.panelRespondentId)
            })

            configBuilder.addTask(redirectTask)
        }
    }

    addGroupConfig(configBuilder: RespondentConfigBuilder<string>): void {
        switch (this.meta.groupId) {
            case 'OMDG-4':
            case 'OMDG-5': {
                addOmdGladeConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }
            case 'AREM-15-MC':
            case 'AREM-16-HTL':
            case 'AREM-17-NTL': {
                addAreMediaConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }

            case 'AUST-18-NEWSFEED':
            case 'AUST-19-WATCH':
            case 'AUST-20-STORIES':
            case 'AUST-21-NEWSFEED':
            case 'AUST-22-STORIES':
            case 'AUST-23':
            case 'AUST-24': {
                addAustCollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }

            case 'C9AU-27-1021-BVOD': {
                addCh9MobileCollConfig(
                    this.meta.groupId,
                    cintExtraMetaKeys,
                    configBuilder
                )

                break;
            }

            case "TWIT-30-1221-UK-TWITTER":
            case "TWIT-31-1221-UK-INSTAGRAM":
            case "TWIT-32-1221-UK-FACEBOOK":
            case "TWIT-34-1221-UK-YOUTUBE":
            case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
            case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
            case "TWIT-38-1221-CAN-TWITTER":
            case "TWIT-39-1221-CAN-INSTAGRAM":
            case "TWIT-40-1221-CAN-FACEBOOK":
            case "TWIT-42-1221-CAN-YOUTUBE":
            case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
            case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
            case "TWIT-46-1221-FRA-TWITTER":
            case "TWIT-47-1221-FRA-INSTAGRAM":
            case "TWIT-48-1221-FRA-FACEBOOK":
            case "TWIT-50-1221-FRA-YOUTUBE":
            case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
            case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
            case "TWIT-54-1221-MEX-TWITTER":
            case "TWIT-55-1221-MEX-INSTAGRAM":
            case "TWIT-56-1221-MEX-FACEBOOK":
            case "TWIT-58-1221-MEX-YOUTUBE":
            case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
            case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
            case "TWIT-33-1221-UK-TIKTOK":
            case "TWIT-41-1221-CAN-TIKTOK":
            case "TWIT-49-1221-FRA-TIKTOK":
            case "TWIT-57-1221-MEX-TIKTOK":
            case "TWIT-35-1221-UK-TWITTER-TIKTOK":
            case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
            case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
            case "TWIT-59-1221-MEX-TWITTER-TIKTOK": {
                addTwit1221CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }

            case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
            case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC":
            case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
            case "REDC-67-0122-IRL-INSTAGRAM-STORIES":
            case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
            case "REDC-69-0122-IRL-YOUTUBE-EXTRAS":
            case "REDC-70-0122-IRL-FACEBOOK-INFEED":
            case "REDC-71-0122-IRL-FACEBOOK-STORIES": {
                addRedc0122CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }

            case "YAHO-72-0422-USA-YAHOO-ITW":
            case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
            case "YAHO-74-0422-USA-FINANCE-ITW":
            case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
            case "YAHO-76-0422-USA-LIFESTYLE-ITW":
            case "YAHO-83-0422-CAN-YAHOO-ITW":
            case "YAHO-84-0422-CAN-SPORTS-ITW":
            case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
            case "YAHO-86-0422-CAN-FINANCE-ITW":
            case "YAHO-87-0422-CAN-AOL-ITW":
            case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
            case "YAHO-94-0422-AUS-YAHOO-ITW":
            case "YAHO-95-0422-AUS-FINANCE-ITW":
            case "YAHO-96-0422-AUS-SPORTS-ITW":
            case "YAHO-102-0422-UK-YAHOO-ITW":
            case "YAHO-103-0422-UK-AOL-ITW":
            case "YAHO-104-0422-UK-FINANCE-ITW":
            case "YAHO-105-0422-UK-STYLE-ITW":
            case "YAHO-106-0422-UK-SPORTS-ITW":
            case "YAHO-77-0422-USA-YAHOO-EXPOSED":
            case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
            case "YAHO-79-0422-USA-FINANCE-EXPOSED":
            case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
            case "YAHO-81-0422-USA-MSN-EXPOSED":
            case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
            case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
            case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
            case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
            case "YAHO-92-0422-CAN-MSN-EXPOSED":
            case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
            case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
            case "YAHO-99-0422-AUS-MSN-EXPOSED":
            case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
            case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
            case "YAHO-107-0422-UK-YAHOO-EXPOSED":
            case "YAHO-108-0422-UK-AOL-EXPOSED":
            case "YAHO-109-0422-UK-MSN-EXPOSED":
            case "YAHO-110-0422-UK-FINANCE-EXPOSED":
            case "YAHO-111-0422-UK-SPORTS-EXPOSED":
            case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
            case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED": {
                addYaho0422CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
            case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
            case "ODEU-115-0422-DEU-FACEBOOK-STORIES":
            case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
            case "ODEU-117-0422-DEU-YOUTUBE-BONUS":
            case "ODEU-118-0422-DEU-TWITTER-INFEED": {
                addOdeu0422CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP":
            case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV":
            case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP":
            case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV":
            case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP":
            case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV":
            case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP":
            case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV":
            case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP":
            case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV":
            case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP":
            case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV":
            case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP":
            case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV":
            case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP":
            case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV":
            case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP":
            case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV":
            case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP":
            case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV":
            case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT":
            case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT":
            case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT":
            case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT":
            case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": {
                addSnap0522CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "ADCO-146-0622-UK-ADCOLONY": {
                addAdco0622CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "VIAB-149-0822-BE-NORTH-YOUTUBE":
            case "VIAB-150-0822-BE-SOUTH-YOUTUBE": {
                addViab0822CollWebplatformConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2":
            case "VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2": {
                addViab0822CollBvodMobileConfig({
                    groupId: this.meta.groupId,
                    viewingCount: 1,
                    metaFieldNames: cintExtraMetaKeys,
                    user: "",
                    email: ""
                }, configBuilder)
                break;
            }

            case "ACAY-151-0822-AUS-YOUTUBE": {
                addAcay0822CollWebplatformConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case 'OMNZ-217-NZ-FACEBOOK':
            case 'OMNZ-218-NZ-INSTAGRAM':
            case 'OMNZ-219-NZ-YOUTUBE-PREROLL':
            case 'OMNZ-220-NZ-TWITTER-FEED':
            case 'OMNZ-221-NZ-TIKTOK-FEED':
            case 'OMNZ-222-NZ-STUFF-EXPOSED':
            case 'OMNZ-223-NZ-NZME-EXPOSED':
            case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': {
                addOmnz1022CollWebplatformConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case 'OMME-234-SAU-FACEBOOK':
            case 'OMME-235-SAU-INSTAGRAM':
            case 'OMME-236-SAU-YOUTUBE-PREROLL':
            case 'OMME-237-SAU-TWITTER-FEED':
            case 'OMME-238-SAU-TIKTOK-FEED':
            case 'OMME-239-SAU-ALMARSD-EXPOSED':
            case 'OMME-240-SAU-ALARABIYA-EXPOSED':
            case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
            case 'OMME-242-SAU-MOTORY-EXPOSED':
            case 'OMME-243-SAU-SO3ODY-EXPOSED':
            case 'OMME-253-UAE-FACEBOOK':
            case 'OMME-254-UAE-INSTAGRAM':
            case 'OMME-255-UAE-YOUTUBE-PREROLL':
            case 'OMME-256-UAE-TWITTER-FEED':
            case 'OMME-257-UAE-TIKTOK-FEED': {
                addOMME1022CollWebplatformConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
            case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
            case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
            case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
            case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
            case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
            case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
            case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
            case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD": {
                addNeau1022CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
            case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
            case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
            case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
            case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED": {
                addTEAD1222CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "TWIC-258-1222-US-MOBILE-ITW":
            case "TWIC-259-1222-UK-MOBILE-ITW":
            case "TWIC-260-1222-AU-MOBILE-ITW": {
                addTwic1222CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }
            case "TWIH-268-0123-US-DESKTOP-ITW":
            case "TWIH-269-0123-UK-DESKTOP-ITW":
            case "TWIH-270-0123-AU-DESKTOP-ITW":
            case "TWIH-271-0123-US-DESKTOP-INT":
            case "TWIH-272-0123-UK-DESKTOP-INT":
            case "TWIH-273-0123-AU-DESKTOP-INT": {
                addTWIH0123CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "SPOT-264-0123-AU-MUSIC":
            case "SPOT-265-0123-AU-PODCAST":
            case "SPOT-266-0123-NZ-MUSIC":
            case "SPOT-267-0123-NZ-PODCAST": {
                addSPOT0123CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "PEPS-274-0523-FACEBOOK-ESTABLISHED":
            case "PEPS-275-0523-FACEBOOK-NON":
            case "PEPS-276-0523-INSTAGRAM-ESTABLISHED":
            case "PEPS-277-0523-INSTAGRAM-NON":
            case "PEPS-278-0523-YOUTUBE-ESTABLISHED":
            case "PEPS-279-0523-YOUTUBE-NON":
            case "PEPS-280-0523-TIKTOK-ESTABLISHED":
            case "PEPS-281-0523-TIKTOK-NON": {
                addPEPS0523CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "GYGG-282-0623-NY-FACEBOOK":
            case "GYGG-283-0623-LA-FACEBOOK":
            case "GYGG-284-0623-NY-INSTAGRAM":
            case "GYGG-285-0623-LA-INSTAGRAM":
            case "GYGG-286-0623-NY-YOUTUBE":
            case "GYGG-287-0623-LA-YOUTUBE": {
                addGYGG0623CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }

            case "SNAR-295-0823-VALIDATION":
                addSNAR0823CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
                
            case "SNAR-316-0823-UK-SCALED-1":
            case "SNAR-317-0823-UK-SCALED-2":
            case "SNAR-318-0823-US-SCALED-1":
            case "SNAR-319-0823-US-SCALED-2":
            case "SNAR-355-0823-FR-SCALED-1":
            case "SNAR-356-0823-FR-SCALED-2":
            case "SNAR-357-0823-SA-SCALED-1":
            case "SNAR-358-0823-SA-SCALED-2":
            case "SNAR-359-0823-CA-SCALED-1":
            case "SNAR-360-0823-CA-SCALED-2":
            case "SNAR-361-0823-CA-SCALED-3":
            case "SNAR-362-0823-AU-SCALED-1":
            case "SNAR-363-0823-AU-SCALED-2":
                addSNAR0823CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            case "SKYN-296-0723-BVOD-MOBILE":
                addSKYN0823CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;

            case "SPIN-288-0823-SPOTIFY":
            case "SPIN-289-0823-FACEBOOK":
            case "SPIN-290-0823-INSTAGRAM":
            case "SPIN-291-0823-YOUTUBE":
            case "SPIN-292-0823-FB-SPOT":
            case "SPIN-293-0823-IG-SPOT":
            case "SPIN-294-0823-YT-SPOT":
                addSPIN0823CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;

            case "PINT-326-1123-US-GROUP1":
            case "PINT-327-1123-US-GROUP2":
            case "PINT-328-1123-US-GROUP3":
            case "PINT-329-1123-US-GROUP4":
            case "PINT-330-1123-UK-GROUP1":
            case "PINT-331-1123-UK-GROUP2":
            case "PINT-332-1123-UK-GROUP3":
            case "PINT-333-1123-UK-GROUP4":
            case "PINT-334-1123-AU-GROUP1":
            case "PINT-335-1123-AU-GROUP2":
            case "PINT-336-1123-AU-GROUP3":
            case "PINT-337-1123-AU-GROUP4":
            case "PINT-338-1123-FR-GROUP1":
            case "PINT-339-1123-FR-GROUP2":
            case "PINT-340-1123-FR-GROUP3":
            case "PINT-341-1123-FR-GROUP4":
            case "PINT-342-1123-DE-GROUP1":
            case "PINT-343-1123-DE-GROUP2":
            case "PINT-344-1123-DE-GROUP3":
            case "PINT-345-1123-DE-GROUP4":
            case "PINT-346-1123-CA-GROUP1":
            case "PINT-347-1123-CA-GROUP2":
            case "PINT-348-1123-CA-GROUP3":
            case "PINT-349-1123-CA-GROUP4":
                addPINT1123CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;
            
            case "RCTV-297-0523-BVODMOBILE":
                addRCTV0523CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;

            case "GYGG-350-0124-US-YOUTUBE-1":
            case "GYGG-351-0124-US-YOUTUBE-2":
            case "GYGG-364-0124-US-FACEBOOK":
            case "GYGG-365-0124-US-INSTAGRAM":
            case "GYGG-366-0124-US-TIKTOK":
            case "GYGG-367-0124-US-YOUTUBE-3":
            case "GYGG-368-0124-UK-YOUTUBE-4":
            case "GYGG-370-0124-US-YOUTUBE-5":
            case "GYGG-371-0124-UK-YOUTUBE-6":
                addGYGG0124CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            case "SPAU-369-0324-SPOTIFY":
                addSPAU0324CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;

            case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
            case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
            case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
            case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
            case "ALLW-376-0424-UK-LOTTO-TIKTOK":
            case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
            case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
                addALLW0424CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            case "SNEM-379-0524-AU-SNAP-HIGH":
            case "SNEM-380-0524-AU-SNAP-MED":
            case "SNEM-381-0524-AU-SNAP-LOW":
            case "SNEM-382-0524-AU-YOUTUBE":
            case "SNEM-383-0524-AU-TIKTOK-HIGH":
            case "SNEM-384-0524-AU-TIKTOK-MED":
            case "SNEM-385-0524-AU-TIKTOK-LOW":
            case "SNEM-386-0524-AU-INSTA-HIGH":
            case "SNEM-387-0524-AU-INSTA-MED":
            case "SNEM-388-0524-AU-INSTA-LOW":
            case "SNEM-389-0524-FR-SNAP-HIGH":
            case "SNEM-390-0524-FR-SNAP-MED":
            case "SNEM-391-0524-FR-SNAP-LOW":
            case "SNEM-392-0524-FR-YOUTUBE":
            case "SNEM-393-0524-FR-TIKTOK-HIGH":
            case "SNEM-394-0524-FR-TIKTOK-MED":
            case "SNEM-395-0524-FR-TIKTOK-LOW":
            case "SNEM-396-0524-FR-INSTA-HIGH":
            case "SNEM-397-0524-FR-INSTA-MED":
            case "SNEM-398-0524-FR-INSTA-LOW":
            case "SNEM-399-0524-SA-SNAP-HIGH":
            case "SNEM-400-0524-SA-SNAP-MED":
            case "SNEM-401-0524-SA-SNAP-LOW":
            case "SNEM-402-0524-SA-YOUTUBE":
            case "SNEM-403-0524-SA-TIKTOK-HIGH":
            case "SNEM-404-0524-SA-TIKTOK-MED":
            case "SNEM-405-0524-SA-TIKTOK-LOW":
            case "SNEM-406-0524-SA-INSTA-HIGH":
            case "SNEM-407-0524-SA-INSTA-MED":
            case "SNEM-408-0524-SA-INSTA-LOW":
            case "SNEM-409-0524-UK-SNAP-HIGH":
            case "SNEM-410-0524-UK-SNAP-MED":
            case "SNEM-411-0524-UK-SNAP-LOW":
            case "SNEM-412-0524-UK-YOUTUBE":
            case "SNEM-413-0524-UK-TIKTOK-HIGH":
            case "SNEM-414-0524-UK-TIKTOK-MED":
            case "SNEM-415-0524-UK-TIKTOK-LOW":
            case "SNEM-416-0524-UK-INSTA-HIGH":
            case "SNEM-417-0524-UK-INSTA-MED":
            case "SNEM-418-0524-UK-INSTA-LOW":
            case "SNEM-419-0524-US-SNAP-HIGH":
            case "SNEM-420-0524-US-SNAP-MED":
            case "SNEM-421-0524-US-SNAP-LOW":
            case "SNEM-422-0524-US-YOUTUBE":
            case "SNEM-423-0524-US-TIKTOK-HIGH":
            case "SNEM-424-0524-US-TIKTOK-MED":
            case "SNEM-425-0524-US-TIKTOK-LOW":
            case "SNEM-426-0524-US-INSTA-HIGH":
            case "SNEM-427-0524-US-INSTA-MED":
            case "SNEM-428-0524-US-INSTA-LOW":
                addSNEM0524CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            case "GCPL-429-0624-FB-IG":
            case "GCPL-430-0624-IG-YT":
            case "GCPL-431-0624-FB-YT":
                addGCPL0624CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;
            
            case "GCPL-432-1024-IN-YOUTUBE":
                addGCPL1024CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;
            
            case "GYGG-433-1024-US-INSTAGRAM":
            case "GYGG-434-1024-US-YOUTUBE":
                addGYGG1024CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            default: assertNever(this.meta.groupId);
        }
    }
}