import { GYGG1024CollGroupId } from "respondent_config_generator/src/projects/GYGG-1024";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { setCurrentLang } from "../../i18n";
import { AppLanguage, getLanguageStrings } from "internationalisation";

const getPlatformForGroupId = (groupId: GYGG1024CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "GYGG-433-1024-US-INSTAGRAM":
            return "instagram";
        case "GYGG-434-1024-US-YOUTUBE":
            return "youtube";
        default:
            assertNever(groupId);
    }
}

export const configureGYGG1024Page = (
    landingPagebuilder: LandingPageConfigBuilder,
    params: CAIParams<GYGG1024CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en);

    landingPagebuilder.setSteps(
        LandingPageConfigBuilder.getStandardViewingSurveySteps(
            getPlatformForGroupId(params.G)
        )
    );

    landingPagebuilder.setPageTitle(
        getLanguageStrings(AppLanguage.en).LandingPage.Title
    );
}