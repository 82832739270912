import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateUploadsTask, generateWebPlatformTask, generateWebRequestTask } from "../tasks";
import { WebPlatform } from "../types";

export const GCPL1024CollGroupIds = [
    "GCPL-432-1024-IN-YOUTUBE"
] as const;


export type GCPL1024CollGroupId = typeof GCPL1024CollGroupIds[number];

const GCPL1024CollWebPlatformIds = [
    "GCPL-1024-IN-YOUTUBE"
] as const;

export type GCPL1024CollWebPlatformId = typeof GCPL1024CollWebPlatformIds[number];

type GCPL1024CollConfig = {
    groupId: GCPL1024CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatform = (groupId: GCPL1024CollGroupId): WebPlatform => {
    switch (groupId) {
        case "GCPL-432-1024-IN-YOUTUBE":
            return "Youtube";
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: GCPL1024CollGroupId): GCPL1024CollWebPlatformId => {
    switch (groupId) {
        case "GCPL-432-1024-IN-YOUTUBE":
            return "GCPL-1024-IN-YOUTUBE";
        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: GCPL1024CollGroupId): string => {
    switch (groupId) {
        case "GCPL-432-1024-IN-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-1024/GCPL-1024-IN-YOUTUBE.json";
        default:
            assertNever(groupId);
    }
}

const getTaskLengthMinutes = (webPlatformId: GCPL1024CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "GCPL-1024-IN-YOUTUBE":
            return "10";
        default:
            assertNever(webPlatformId);
    }
}

export const addGCPL1024CollConfig = (
    config: GCPL1024CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    // Provided by Ops
    const FINISH_MESSAGE = "You have successfully completed your Blinq adventure and your rewards will be processed by your panel provider. This could take up to 10 business days, so please touch base with them directly if you have any questions. We thank you for being a part of the Blinq family, and hope to see you again soon.";

    const webPlatformId = getWebPlatformId(config.groupId);
    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        platform: getWebPlatform(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        webPlatformID: webPlatformId,
        langStrings: getLanguageStrings(AppLanguage.en),
        taskLengthMinutes: getTaskLengthMinutes(webPlatformId),
        useWebPlatformIDForTaskID: true
    });

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/GCPL-1024/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST'
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS'
    });

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .addTask(webPlatformTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask);

    return builder;
}