import { LangStrings } from './LangStrings';

const EsLangStrings: LangStrings = {
    "Common": {
        "survey": "Encuesta",
        "back": "Atrás",
        "next": "siguinente",
        "loading": "Cargando",
        "go": ""
    },
    "Survey": {
        "description": "A 5 minute survey on your shopping preferences"
    },
    "WebPlatform": {
        "facebook": (minutes: string) => `Scroll Facebook for ${minutes} minutes`,
        "youtube": (minutes: string) => `Scroll YouTube for ${minutes} minutes`,
        "instagram": (minutes: string) => `Scroll Instagram for ${minutes} minutes`,
        "tiktok": (minutes: string) => `Scroll TikTok for ${minutes} minutes`,
        "snapar": () => 'Explore the provided lenses simply by tapping on each one. You can play games, try on accessories and take pictures with characters. We’ll let you know when the session is up.'
    },
    "LandingPage": {
        "Title": "Instrucciones de configuración",
    },
    "Decay": {
        "Surveys": {
            "Initial": "Shopping Survey",
            "TwoWeek": "2 Week Shopping Survey",
            "FourWeek": "4 Week Shopping Survey"
        }
    },
    "BvodInApp": {
        "title": "TV Show Viewing",
        "description": "Watch a TV show on your phone",
    },
    "Steps": {
        "StepLabel": {
            "Instructions": "Instrucciones",
            "ViewingInstructionsDeskSolution": "Instrucciones",
            "Requirements": "Requisitos",
            "AppDownload": "Descargar de la aplicación",
            "AppDownloadDeskSolution": "",
            "LoginAndCompletionCode": "Inicie sesión",
            "MturkGazeDesktopCompletion": "Inicie sesión",
            "LoginAndCompletionDeskSolution": "",
            "SessionInformationDeskSolution": "",
            "Setup": "",
            StartEarning: ""
        },
        ViewingInstructions: {
            title: "Gracias por participar en nuestro estudio. Siga los pasos para descargar nuestra aplicación y comenzar.",
            subtitle: "Para comenzar, lo guiaremos para que descargue nuestra aplicación para iPhone y obtenga un ID de inicio de sesión. Clic “siguinente” para continuar."
        },
        ViewingInstructionsDeskSolution: {
            title: "",
            subtitle: "",
        },
        "AppDownload": {
            "subtitle": 'En la App Store de Apple, busque "attentionTRACE Research"',
            "or": "O",
            "downloadLink": "Haga clic aquí para descargar nuestra aplicación"
        },
        "AppDownloadDeskSolution": {
            "subtitle": "",
            "downloadLink": "",
        },
        "LoginAndCompletionCode": {
            "subtitle": "Utilice este código para iniciar sesión en attentionTRACE:",
            platformInstructions: {
                facebookNewsfeed: "Inicie sesión en su cuenta de Facebook y desplácese por el suministro de noticias. Haz todo lo que normalmente harías; Desplácese, haga clic en Me gusta y comente en algunas publicaciones y vea algunas historias hasta que se acabe el tiempo.\nNecesitará sus datos de inicio de sesión de Facebook antes de comenzar",
                facebookWatch: "",
                facebookStories: "",
                instagram: '',
                instagramNewsfeed: "Inicie sesión en su cuenta de Instagram y desplácese por su “feed”. ¡Haz todo como lo harías normalmente, dale me gusta y comenta algunas publicaciones y mira algunas historias hasta que se acabe el tiempo!\nNecesitará sus datos de inicio de sesión de Instagram antes de comenzar",
                youtube: "Una vez que esté dentro, use YouTube como lo haría normalmente, viendo algunos videos diferentes. Le recomendamos que inicie sesión en su cuenta de Google, así que tenga esos detalles listos cuando comience a ver.\nLa aplicación le notificará cuando se acabe el tiempo.",
                youtubeShorts: "Para reclamar su recompensa, debe navegar por YouTube y ver su contenido favorito en cortometrajes. No es necesario iniciar sesión.\nDisfruta desplazándote, viendo vídeos y dando me gusta a las publicaciones hasta que se acabe el tiempo.\n\nUna vez finalizada esta sesión, ¡habrás terminado!\n\nEso significa que en menos de 10 minutos Desbloqueas tus recompensas. El procesamiento se llevará a cabo dentro de los próximos 10 días. ¡¿Qué genial es eso?!\n\nEntonces, ¿qué estás esperando? ¡Es hora de profundizar más y ganar esas recompensas!",
                twitter: "Necesitará los datos de inicio de sesión de su cuenta de Twitter listos para ingresar. Una vez que esté dentro, use Twitter como lo haría normalmente.\nDesplácese por el \"feed\" y vea las tendencias hasta que la aplicación le notifique que se acabó el tiempo.",
                tiktok: "Necesitará los datos de inicio de sesión de su cuenta de TikTok listos para ingresar. Una vez que esté dentro, use TikTok como lo haría normalmente.\nDesplácese por el \"feed\" y vea las tendencias hasta que la aplicación le notifique que se acabó el tiempo.",
                webplatformSynergy: "Necesitará los datos de inicio de sesión de su cuenta de Twitter listos para ingresar. Una vez que esté dentro, use Twitter como lo haría normalmente. Desplácese por el feed y vea las tendencias hasta que la aplicación le notifique que se acabó el tiempo.\nUna vez que se acabe el tiempo, la aplicación cargará automáticamente la siguiente plataforma, ya sea Instagram, Facebook o TikTok. Así que tenga a mano esos datos de inicio de sesión.",
                pinterest: "Inicie sesión en la aplicación de attentionTRACE utilizando el código de inicio de sesión único que aparece arriba.\nUna vez que haya iniciado sesión, se le presentará la plataforma de Pinterest y se le pedirá que inicie sesión en su cuenta personal.\nNavega y explora Pinterest libremente siguiendo las indicaciones(consulte la página de exploración, navegue por las categorías sugeridas e interactúe con el contenido) hasta que se acabe el cronómetro.\nSerá redirigido a una encuesta, simplemente complétela y ¡listo!",
                snapar: "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will presented with a collection of Snapchat AR lenses. \nPlease tap on each lens to utilise it and interact with the lens as you normally would until the timer is up.",
                snapchat: ''
            }
        },
        "MturkGazeDesktopCompletion": {
            "subtitle": "Utilice este código para iniciar sesión en attentionTRACE:"
        },
        "LoginAndCompletionDeskSolution": {
            "subtitle": ""
        },
        "SessionInformationDeskSolution": {
            "description": ""
        },
        "Error": {
            title: "¡UH oh!",
            subTitle: "Ocurrió un error inesperado. Vuelva a intentarlo más tarde.",
        },
        Setup: [
            {
                key: "loginCode",
                title: "Step 1: Record your Login ID",
                description: "This code will be your key to login to the Blinq Research App and start earning. Keep it in a safe place, just in case."
            },
            {
                key: "appDownload",
                title: "Step 2: Download the Blinq Research App",
                description: "Either scan the QR code using the camera on your phone, or click on the App Store logo to download directly."
            }
        ],
        StartEarning: 'Once you’ve downloaded the app, it’s time to enter your Login ID.'
    },
    StepsFooter: {
        description: "Si tiene problemas, puede {contactUs}. Incluya su {loginIdString} en su consulta por correo electrónico.",
        contactUs: "Contacta con nosotros",
        privacyPolicy: "política de privacidad"
    }
}
export default EsLangStrings