import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask } from "../tasks";
import { WebPlatform } from "../types";


export const GYGG1024CollGroupIds = [
    "GYGG-433-1024-US-INSTAGRAM",
    "GYGG-434-1024-US-YOUTUBE"
] as const;

export type GYGG1024CollGroupId = typeof GYGG1024CollGroupIds[number];

export const GYGG1024CollWebPlatformIds = [
    "GYGG-1024-US-INSTAGRAM",
    "GYGG-1024-US-YOUTUBE"
] as const;

export type GYGG1024CollWebPlatformId = typeof GYGG1024CollWebPlatformIds[number]

type GYGG1024CollConfig = {
    groupId: GYGG1024CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: GYGG1024CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "GYGG-1024-US-INSTAGRAM":
        case "GYGG-1024-US-YOUTUBE":
            return "10";
        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: GYGG1024CollGroupId): string => {
    switch (groupId) {
        case "GYGG-433-1024-US-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-1024/GYGG-1024-US-INSTAGRAM.json";
        case "GYGG-434-1024-US-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-1024/GYGG-1024-US-YOUTUBE.json";
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: GYGG1024CollGroupId): GYGG1024CollWebPlatformId => {
    switch (groupId) {
        case "GYGG-433-1024-US-INSTAGRAM":
            return "GYGG-1024-US-INSTAGRAM";
        case "GYGG-434-1024-US-YOUTUBE":
            return "GYGG-1024-US-YOUTUBE";
        default:
            assertNever(groupId);
    }
}

const getWebPlatform = (groupId: GYGG1024CollGroupId): WebPlatform => {
    switch (groupId) {
        case "GYGG-433-1024-US-INSTAGRAM":
            return "Instagram";
        case "GYGG-434-1024-US-YOUTUBE":
            return "Youtube";
        default:
            assertNever(groupId);
    }
}

export const addGYGG1024CollConfig = (
    config: GYGG1024CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const FINISH_MESSAGE = "Congratulations! You have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

    const webPlatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        platform: getWebPlatform(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        webPlatformID: webPlatformId,
        langStrings: getLanguageStrings(AppLanguage.en),
        taskLengthMinutes: getTaskLengthMinutes(webPlatformId),
        useWebPlatformIDForTaskID: true
    });

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/GYGG-1024/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .addTask(webplatformTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask);

    return builder;
}